textarea:focus, input:focus {
  outline: none;
}

#spotlight-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  font-size: 2rem;
  overflow-y: auto;
  border-radius: 0.6rem;
  transition: height 0.2s ease-out;
  user-select: none;
}

#spotlight-container:after {
  background: rgba( 0, 0, 0, 0.70 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 9.0px );
  -webkit-backdrop-filter: blur( 9.0px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  content: '';
  height: 100%;
  position: absolute;
  width: 100%;
  user-select: none;
}

#spotlight-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5vh;
  width: 97%;
  z-index: 1;
}

#searchIcon-container {
  height: 100%;
  width: 3rem;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#searchIcon, #hamburgerIcon {
  z-index: 5;
  height: 1.5rem;
  width: auto;
}

#hamburgerIcon {
  cursor: pointer;
  transition: all 0.5s;
}

#hamburgerIcon:hover {
  transform: scale(1.2);
}

#inputArea-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#input-icon {
  height: 1.5rem;
  width: auto;
  margin: 1rem;
}

#inputArea {
  width: 100%;
  height: 100%;
  background: transparent;
  color: var(--light-gray);
  box-sizing: border-box;
  border: none;
  z-index: 2;
  font-size: 1.6rem;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

#inputArea-text {
  width: 100%;
  height: 100%;
  background: transparent;
  box-sizing: border-box;
  border: none;
  z-index: 2;
  font-size: 1.6rem;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


@media (max-height: 900px) {

  #spotlight-inner {
    height: 7vh;
  }


  #input-icon {
    height: 3vh;
    margin: 0.5vh 1vh;
  }

  #search-icon, #hamburger-icon {
    height: 3vh;
  }
  
}