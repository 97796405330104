@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
:root {
  --celadon-green: #36827fff;
  --blue-ryb: #2f52e0ff;
  --blue-ryb-lt: rgba(47, 82, 224, 0.7);
  --umber: #554640ff;
  --light-gray: #ced0ceff;
  --raisin-black: #2e2c2fff;
  --root-rem: 16px;
}

html {
  font-size: 16px;
  font-size: var(--root-rem);
  color: #ced0ceff;
  color: var(--light-gray);
}


@media (max-width: 900px) {
  html { font-size: 13px; }
}

@media (max-width: 600px) {
  html { font-size: 11px; }
}

@media (max-width: 400px) {
  html { font-size: 8px; }
}

@media screen and (max-width: 767px) {
    _::-webkit-full-page-media, _:future, :root .safari_only {
        padding-bottom: 65px; 
    }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul, li {
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow-y: hidden;
  scrollbar-width: none;
}

#BackgroundCenter {
  height: 100%;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.content-card-visible {
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid rgba(249, 253, 253, 0.377);
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding: 2rem;
  /* overflow-y: scroll; */
}

::-webkit-scrollbar {
  display: none;
}

textarea:focus, input:focus {
  outline: none;
}

#spotlight-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  font-size: 2rem;
  overflow-y: auto;
  border-radius: 0.6rem;
  transition: height 0.2s ease-out;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#spotlight-container:after {
  background: rgba( 0, 0, 0, 0.70 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 9.0px );
  -webkit-backdrop-filter: blur( 9.0px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  content: '';
  height: 100%;
  position: absolute;
  width: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#spotlight-inner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  height: 5vh;
  width: 97%;
  z-index: 1;
}

#searchIcon-container {
  height: 100%;
  width: 3rem;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#searchIcon, #hamburgerIcon {
  z-index: 5;
  height: 1.5rem;
  width: auto;
}

#hamburgerIcon {
  cursor: pointer;
  transition: all 0.5s;
}

#hamburgerIcon:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

#inputArea-container {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#input-icon {
  height: 1.5rem;
  width: auto;
  margin: 1rem;
}

#inputArea {
  width: 100%;
  height: 100%;
  background: transparent;
  color: var(--light-gray);
  box-sizing: border-box;
  border: none;
  z-index: 2;
  font-size: 1.6rem;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6rem;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

#inputArea-text {
  width: 100%;
  height: 100%;
  background: transparent;
  box-sizing: border-box;
  border: none;
  z-index: 2;
  font-size: 1.6rem;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6rem;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}


@media (max-height: 900px) {

  #spotlight-inner {
    height: 7vh;
  }


  #input-icon {
    height: 3vh;
    margin: 0.5vh 1vh;
  }

  #search-icon, #hamburger-icon {
    height: 3vh;
  }
  
}
.search-list-container {
  width: 100%;
  z-index: 2;
  margin: 0 auto;
  padding: 0 0 0.5rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: start;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
}

.section-ul {
  font-size: 1rem;
  padding-left: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  border-top: 1px solid rgba(249, 253, 253, 0.377);
}

.section-ul-title, .section-list-item {
  text-align: left;
}

.section-ul-title {
  font-size: 0.75rem;
  color: #ced0ce9c;
  margin-top: 0.25rem;
  padding-left: 3rem;
  width: 97%;
  box-sizing: border-box;
}

.list-item-outer-container {
  width: 100%;
}

.section-list-item {
  margin: 0.25rem auto;
  border-radius: 3px;
  padding: 0.25rem 0;
  width: 97%;
  box-sizing: border-box;
}

.section-list-item:hover {
  background-color: var(--blue-ryb-lt);
  cursor: pointer;
}

.content-area-container {
  width: 100%;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  overflow-y: auto;
}

::-webkit-scrollbar {
  display: none;
}

.section-list-card-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.section-list-card-icon-container {
  width: 3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
}

.section-list-icon {
  width: auto;
  height: 1rem;
  margin: 0 auto;
}
.about-card-name {
  margin: 2rem;
}

.about-card-text {
  font-size: 1rem;
  margin: 1rem;
  text-align: center;
  width: 60%;
  line-height: 2rem;
}

@media (max-width: 900px) {
  .about-card-text { 
    width: 60%; 
  }
}

@media (max-width: 600px) {
  .about-card-text { 
    width: 100%;
    line-height: 1.5rem;
    margin: 0.5rem;
  }
  .about-card-name {
    margin: 1rem;
  }
}
.skill-card {
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.skill-section-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 10rem;
  height: 8.5rem;
}

.skill-icon-container {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  
}

.skills-icon {
  width: 4rem;
  height: auto;
}

.skills-icon.mongodb {
  height: 4rem;
  width: auto;
}

.skill-item-title {
  margin-top: 1rem;
  font-size: 1.5rem;
}
.project-page-container {
  width: 100%;
  z-index: 2;
  padding: 0 0 0.5rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: start;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
}

.project-list-item {
  border-top: 1px solid rgba(249, 253, 253, 0.377);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 2rem 1rem;
}

.project-card-img-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.project-list-item-text {
  width: 75%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 30%;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.project-list-item-description {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: initial;
          align-items: initial;
  height: 100%;
}

.project-list-props {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 1rem 0;
  font-size: 1rem;
  line-height: 2rem;
  -webkit-align-items: baseline;
          align-items: baseline;
  -webkit-justify-content: center;
          justify-content: center;
}

.project-list-props.Stack {
  font-size: 1.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  margin: 0 auto;
  font-weight: 700;
}

.prop-title {
  margin-right: 1rem;
  font-size: 2rem;
  font-weight: 700;
}

.prop-value.tagline {
  text-align: center;
}

.project-list-link-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  height: 30%;
  width: 60%;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 80px;
  background-color: var(--light-gray);
  color: var(--raisin-black);
  font-size: 1rem;
  text-align: center;
  padding: 5px 0;
  border-radius: 0.5rem;
  position: absolute;
  top: 120%;
  left: 50%;
  margin-left: -40px;
  z-index: 1;
}

.feature-image-container {
  height: 40%;
  width: auto;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.feature-video {
  border-radius: 1rem;
}

.contact-card-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 2rem;
}

.contact-info-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  height: 80%;
  width: 100%;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.name-location-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.contact-card-name {
  margin-top: 20px;
  font-weight: 700;
  font-size: 2rem;
}

.contact-card-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.socials-list {
  display: -webkit-flex;
  display: flex;
  direction: row;

}

.basic-contact-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.contact-info-item {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem 1rem;
  margin: 1rem;
  border-radius: 1.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  transition: all 0.5s;
}

.contact-info-item a {
  text-decoration: none;
  color: var(--light-gray);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

#email-icon, #call-icon, #location-icon {
  width: 1.5rem;
  height: auto;
  margin-right: 1.5rem;
}

.contact-info-item:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background-color: var(--blue-ryb);
}

.contact-card-text {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.contact-subtext {
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

.socials-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin: 2rem 1rem;
  width: 80%;
}

.socials-link-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 1rem;
  padding: 1rem;
  height: 4rem;
  width: 4rem;
  border-radius: 0.6rem;
  transition: all 0.5s;
}

.socials-link-container:hover {
  background-color: var(--umber);
}

.socials-link-icon {
  cursor: pointer;
  box-sizing: border-box;
  width: auto;
  height: 4rem;
  transition: all 0.5s;
  margin: 0 auto;
}

.socials-link-icon:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media (max-width: 900px) {
  .socials-link-container { 
    margin: 1rem; 
  }
}

@media (max-width: 600px) {
  .socials-link-container { 
    margin: 0.5rem;
  }
  .socials-list {
    margin: 0.5rem;
  }
}

@media (max-width: 900px) {
  .contact-card-image { 
    width: 200px; 
    height: 200px;
  }
}

@media (max-width: 600px) {
  .contact-card-image { 
    width: 100px; 
    height: 100px;
  }
}

@media (max-height: 900px) {
  .contact-card-image {
    width: 100px;
    height: 100px;
  }

  .socials-list {
    margin: 1rem 0.5rem;
  }

  .socials-link-container {
    margin: 0;
  }

  .socials-link-icon {
    height: 3rem;
  }
  
  .about-card-text {
    line-height: 1.5rem;
  }

  .about-card-name {
    margin: 1rem;
  }
}
.content-card-visible.experience {
  padding: 1rem;
}

.experience-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 1.25rem;
  width: 90%;
}

.experience-fieldset {
  border: 1px solid var(--light-gray);
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.experience-title {
  font-size: 1.25em;
  font-weight: 700;
  margin-left: 1.2rem;
}

.experience-subtitle {
  display: -webkit-flex;
  display: flex;
  direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-left: 1.2rem;
  font-size: 1rem;
}

.company {
  font-weight: 700;
}

.duration {
  font-style: italic;
}

.experience-list {
  font-size: 1rem;
  line-height: 1.2rem;
  padding: 0 0 0 1rem;
}

.bullet {
  list-style: circle;
  margin: 0.5rem;
}


@media (max-height: 900px) {
  .content-card-visible.experience {
    padding: 3px;
  }
  
  .experience-container {
    font-size: 13px;
  }

  .experience-title {
    font-size: 14px;
  }

  .experience-subtitle {
    font-size: 13px;
    margin-bottom: 3px;
  }

  .experience-list {
    font-size: 13px;
    line-height: 13px;
    padding: 0 0 0 13px;
  }

  .bullet {
    margin: 3px;
  }
}

