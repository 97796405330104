@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --celadon-green: #36827fff;
  --blue-ryb: #2f52e0ff;
  --blue-ryb-lt: rgba(47, 82, 224, 0.7);
  --umber: #554640ff;
  --light-gray: #ced0ceff;
  --raisin-black: #2e2c2fff;
  --root-rem: 16px;
}

html {
  font-size: var(--root-rem);
  color: var(--light-gray);
}


@media (max-width: 900px) {
  html { font-size: 13px; }
}

@media (max-width: 600px) {
  html { font-size: 11px; }
}

@media (max-width: 400px) {
  html { font-size: 8px; }
}

@media screen and (max-width: 767px) {
    _::-webkit-full-page-media, _:future, :root .safari_only {
        padding-bottom: 65px; 
    }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul, li {
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
