.App {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow-y: hidden;
  scrollbar-width: none;
}

#BackgroundCenter {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.content-card-visible {
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid rgba(249, 253, 253, 0.377);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
  /* overflow-y: scroll; */
}

::-webkit-scrollbar {
  display: none;
}
