.skill-card {
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.skill-section-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 10rem;
  height: 8.5rem;
}

.skill-icon-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.skills-icon {
  width: 4rem;
  height: auto;
}

.skills-icon.mongodb {
  height: 4rem;
  width: auto;
}

.skill-item-title {
  margin-top: 1rem;
  font-size: 1.5rem;
}